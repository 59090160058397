<template>
	<div id="usuarios" v-if="dados_crud[crud] != undefined">
		<div class="col-12 novo-usuario">
			<div class="row justify-end">
				<v-btn color="secondary" raised @click="dialog_cadastrar_editar = true;codigo = '';">
					<i class="mr-2 fas fa-plus"></i> {{dados_crud[crud].texto_cadastrar}}
				</v-btn>
			</div>
		</div>
        <CustomTable 
			v-if="dados_crud[crud].headers != ''"
			:action="'getCrudDinamicoRead'"
			:getter="$store.getters.paginacao_crud_dinamico"
			:headers="dados_crud[crud].headers"
			:search="true"
			:title="dados_crud[crud].texto_tabela"
			:pagination="true"
			:filters="{filtros:dados_crud[crud].filtros,coluna_id:dados_crud[crud].coluna_id,crud,campos:dados_crud[crud].headers}"
			ref="tabela"
		>
			<template v-slot:status="{ item }">
				{{item.status ? 'Ativo' : 'Inativo' }}
			</template>
			<template v-slot:acoes="{ item }">
				<v-btn color="primary" raised x-small @click="editar(item[dados_crud[crud].coluna_id],dados_crud[crud].coluna_id)">
					Editar {{dados_crud[crud].texto_botoes_singular}}
				</v-btn>
			</template>
		</CustomTable>
		<v-dialog v-model="dialog_cadastrar_editar" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span v-if="codigo != ''" class="headline">Editar {{dados_crud[crud].texto_botoes_singular}}</span>
					<span v-else class="headline">Novo {{dados_crud[crud].texto_botoes_singular}}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_usuario">
							<div class="row">
								<div class="col-12 col-md-6"  v-for="(campo, i) in dados_crud[crud].campos" :key="i">
									<v-text-field 
										:rules="[v => !!v || 'Campo ' + campo.label + ' obrigatório']" 
										v-model="dados_crud[crud].campos[i].value" 
										:label="campo.label" 
										:placeholder="campo.placeholder" 
										outlined
										v-if="(campo.tipo =='input' && verificaIf(campo.if_coluna_antes,campo.if_comparador,campo.if_resultado))"
									/>

									<v-select 
										:items="campo.dados"
										:item-text="campo.coluna_texto" 
										:item-value="campo.coluna_id"
										v-model="dados_crud[crud].campos[i].value" 
										:label="campo.label" 
										:placeholder="campo.placeholder" 
										outlined
										v-if="campo.tipo =='select' && verificaIf(campo.if_coluna_antes,campo.if_comparador,campo.if_resultado)"
									/>
									
									<v-autocomplete
										:rules="[v => !!v || 'Campo ' + campo.label + ' obrigatório']" 
										:items="campo.dados"
										:item-text="campo.coluna_texto" 
										:item-value="campo.coluna_id"
										v-model="dados_crud[crud].campos[i].value" 
										:label="campo.label" 
										:placeholder="campo.placeholder" 
        								:search-input.sync="dados_crud[crud].campos[i].search"
        								:loading="loading_busca"
										outlined
										v-if="campo.tipo =='select-ajax'&& verificaIf(campo.if_coluna_antes,campo.if_comparador,campo.if_resultado)"
									/>
								</div>
								
							</div>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">
						Cancelar
					</v-btn>
					<v-btn color="primary" large @click="enviar">
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		name: 'Usuarios',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			crud: '',
			// variável para mostrar a modal para editar/criar um usuário
			dialog_cadastrar_editar: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			loading_busca:false,
         	searchInput: {},
			// variável para o loading
			loading: false,
			// variável para os grupos
			dados_crud:{
				'empresa_servicos'  :{
					headers:[
						{
							value: 'servico',
							text: 'Serviço',
							searchable: true,
							sortable: true,
						},
						{
							value: 'url_amigavel',
							text: 'URL Amigavel',
							searchable: true,
							sortable: true,
						},
						{
							value: 'url_sistema',
							text: 'URL SISTEMA',
							searchable: true,
							sortable: true,
						},
						{
							value: 'status',
							text: 'Status',
							searchable: false,
							sortable: true,
						},
						{
							value: 'empresa.0.nome',
							text: 'Empresa(s)',
							searchable: true,
							sortable: true,
						},
						{
							value: 'acoes',
							sortable: false,
							searchable: false,
							text: 'Ações',
						},
					],
					campos:{
						empresa:{
							tipo:'select-ajax',
							label:'Empresa',
							crud:'empresas',
							required:true,
							dados:[
							],
							coluna_id:'_id',
							coluna_texto:'nome',
							placeholder: 'Empresa do Serviço do Delivery',
							value:'',
							search:'',
							if_coluna_antes:'',
							if_comparador:'',
							if_resultado:''
						},
						status:{
							tipo:'select',
							label:'Situação',
							required:true,
							dados:[
								{
									_id:true,
									nome:'Ativo'
								},
								{
									_id:false,
									nome:'Inativo'
								},
							],
							coluna_id:'_id',
							coluna_texto:'nome',
							placeholder: 'Situação',
							value:'',
							if_coluna_antes:'',
							if_comparador:'',
							if_resultado:''
						},
						servico:{
							tipo:'select',
							label:'Serviço',
							required:true,
							dados:[
								{
									_id:'ifood',
									nome:'Ifood'
								},
								{
									_id:'deliveryfiner',
									nome:'Delivery Finer'
								},
							],
							coluna_id:'_id',
							coluna_texto:'nome',
							placeholder: 'Serviço do Delivery',
							value:'',
							if_coluna_antes:'',
							if_comparador:'',
							if_resultado:''
						},
						merchant_id:{
							tipo:'select-ajax',
							label:'Merchant',
							api:'api/ifood/getMerchants',
							required:true,
							dados:[
							],
							coluna_id:'id',
							coluna_texto:'name',
							placeholder: 'Merchant do Serviço do Delivery',
							value:'',
							search:'',
							if_coluna_antes:'servico',
							if_comparador:'==',
							if_resultado:'ifood'
						},
						url_amigavel:{
							tipo:'input',
							label:'URL Amigavel',
							required:true,
							placeholder: 'Url Amigavel usada para buscar quando for delivery',
							value:'',
							if_coluna_antes:'servico',
							if_comparador:'==',
							if_resultado:'deliveryfiner'
						},
					},
					filtros:{
						perPage: 10
					},
					coluna_id:'_id',
					texto_cadastrar:'Cadastrar Serviço da Empresa',
					texto_tabela:'Serviços',
					texto_botoes_singular:'Serviço'
				},

				'produto_categorias'  :{
					headers:[
						{
							value: 'nome',
							text: 'Nome',
							coluna_table:'nome',
							searchable: true,
							sortable: true,
						},
						{
							value: 'status',
							text: 'Status',
							searchable: false,
							sortable: true,
						},
						{
							value: 'acoes',
							sortable: false,
							searchable: false,
							text: 'Ações',
						},
					],
					campos:{
						nome:{
							tipo:'input',
							label:'Nome',
							required:true,
							placeholder: 'Nome' ,
							value:''
						},
						empresa_id:{
							tipo:'select-ajax',
							label:'Empresa',
							crud:'empresas',
							required:true,
							campos_select:{
								'empresa_id':'empresa_id',
								'nome':'nome'
							},
							dados:[
								
							],
							coluna_id:'empresa_id',
							coluna_texto:'nome',
							placeholder: 'Empresa',
							value:'',
							search:'',
							if_coluna_antes:'',
							if_comparador:'',
							if_resultado:''
						},
						status:{
							tipo:'select',
							label:'Situação',
							required:true,
							dados:[
								{
									_id:true,
									nome:'Ativo'
								},
								{
									_id:false,
									nome:'Inativo'
								},
							],
							coluna_id:'_id',
							coluna_texto:'nome',
							placeholder: 'Situação',
							value:'',
							if_coluna_antes:'',
							if_comparador:'',
							if_resultado:''
						}
					},
					filtros:{
						perPage: 10
					},
					coluna_id:'produto_categoria_id',
					texto_cadastrar:'Cadastrar Categoria',
					texto_tabela:'Categorias',
					texto_botoes_singular:'Categoria'
				}
			},
			// variável para os grupos
			grupos: [],
			// variável para as empresas
			empresas: [],
			// variável para criar/editar usuário
			codigo:''
		}),
		watch:{
		},
		// funções deste componente
		methods: {
			ajaxBuscaDados(val){
				console.log(val)
			},
			verificaIf(if_coluna_antes,if_comparador,if_resultado){
				var retorno = if_comparador == '' || if_comparador == undefined ? true : false;

				if(if_coluna_antes != '' && if_comparador != '' && if_resultado !='' ){
					switch (if_comparador) {
						case '==':
							retorno = this.dados_crud[this.crud].campos[if_coluna_antes].value == if_resultado;
						break;
					}
				}

				return retorno
			},
			// funções de incio do componente
			async init(){
				this.crud = this.$route.params.crud;

				if(this.dados_crud[this.crud] == undefined){
					this.$router.push('/admin/sem-permissao')
				}else{
					this.getSelects();
					console.log(this.dados_crud[this.crud])
				}
			},
			async getSelects(){
				var campos = Object.keys(this.dados_crud[this.crud].campos)
				var i = 0;

				for (i = 0; i < campos.length; i++) {
					let campo = campos[i];

					if(this.dados_crud[this.crud].campos[campo].tipo == 'select-ajax'){
						this.$watch(['dados_crud', this.crud, 'campos', campo, 'search'].join('.'), async (novo_valor) => {
							let busca = {};
							this.loading_busca = true

							busca['crud'] = this.dados_crud[this.crud].campos[campo].crud;
							busca['api'] = this.dados_crud[this.crud].campos[campo].api;
							busca['campo'] = this.dados_crud[this.crud].campos[campo].coluna_texto;
							busca[this.dados_crud[this.crud].campos[campo].coluna_texto] = novo_valor;
							if(this.dados_crud[this.crud].campos[campo].campos_select){
								busca['campos_select'] = this.dados_crud[this.crud].campos[campo].campos_select;
							}
							busca['tipo'] = busca['crud'] == undefined ? 'api': 'crud';
							
							
							var resp = await store.dispatch('getSelectCrudDinamico', busca)

							if(resp.status == 200){
								this.dados_crud[this.crud].campos[campo].dados = resp.data.dados;

							}else{
								this.dados_crud[this.crud].campos[campo].dados = []
							}
							this.loading_busca = false

						});
					}
				}
			},
			// função para enviar um usuário
			async enviar(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_usuario.validate()){
					// coloca o componente como loading
					this.loading = await true

					var campos = Object.keys(this.dados_crud[this.crud].campos)
					var i = 0;
					// coloeta os dados do usuário
					let dados = {
						crud:this.crud,
						campos:campos
					};

					for (i = 0; i < campos.length; i++) {
						let campo = campos[i];
						dados[campo] = this.dados_crud[this.crud].campos[campo].value
					}
					// // caso exista um usuario_id 
					if(this.codigo != ''){
						// coleta o status do usuário
						dados.codigo = await this.codigo
						// coleta o status do usuário
						dados.coluna_id = await this.dados_crud[this.crud].coluna_id
					}
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postCrudDinamico', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await  this.dados_crud[this.crud].texto_botoes_singular + ' ' +  (this.codigo != '' ? 'editado!' : 'cadastrado!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await this.dados_crud[this.crud].texto_botoes_singular + ' ' + (this.codigo != '' ? 'editado' : 'cadastrado') + ' com sucesso!'
						// mostra a mensagem
						this.close()
						// fecha a modal de create/edit usuário
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um usuário para editar
			async editar(codigo,coluna_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um usuário passando o id 
				var resp = await store.dispatch('getCrudDinamico', {codigo,coluna_id,crud:this.crud,campos:Object.keys(this.dados_crud[this.crud].campos)})
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200 && resp.data.dados != undefined){
					let dados = resp.data.dados != undefined ? resp.data.dados : {};
					// // atribui os dados do usuário vindos do back à váriável local
					this.codigo = dados[this.dados_crud[this.crud].coluna_id];
					var campos = Object.keys(this.dados_crud[this.crud].campos)
					var i = 0;

					for (i = 0; i < campos.length; i++) {
						let campo = campos[i];

						if(dados[campo] != undefined){
							this.dados_crud[this.crud].campos[campo].value = dados[campo]
						}
					}
					
					this.dialog_cadastrar_editar = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
			},
			// função que roda quando é fechada a modal de create/edit usuário
			close(){
				// fecha a modal
				this.dialog_cadastrar_editar = false
				var campos = Object.keys(this.dados_crud[this.crud].campos)
				var i = 0;

				this.codigo = ''

				for (i = 0; i < campos.length; i++) {
					let campo = campos[i];
					this.dados_crud[this.crud].campos[campo].value = ''
				}
			}
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
		}
	}
</script>

<style lang="scss">
	#usuarios{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
	}
</style>